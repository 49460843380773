<template>
  <div class="live-card-wrap">
    <el-card
      border="false"
      shadow="hover"
      class="live-card"
      @click.native="handleClick"
    >
      <div class="time">{{ fmtStartTime }}</div>
      <div class="live-info">
        <el-image
          class="logo"
          :src="this.$global.assertBaseUrl + data.logo"
        ></el-image>
        <div class="right-content">
          <div class="name">{{ data.courseName }}</div>
          <div class="price-num" v-if="!isBuy">
            <span class="buy-num">已售{{ data.payNum }}人</span>
            <span class="price">{{
              data.labelPrice === 0 ? "免费" : `¥${data.labelPrice}`
            }}</span>
          </div>
          <div class="to-watch" v-else>
            去观看>>
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "LiveCard",

  props: {
    data: Object,
    isBuy: Boolean
  },
  data() {
    return {};
  },
  computed: {
    fmtStartTime() {
      return moment(this.data.startTime).format("MM月DD日 HH:mm");
    }
  },
  methods: {
    handleClick() {
      if (this.isBuy) {
        this.$router.push({
          path: "user-course-detail",
          query: {
            courseId: this.data.courseId
          }
        });
        return;
      }
      this.$router.push({
        path: "/course-detail-single",
        query: {
          id: this.data.goodsId
        }
      });
    }
  }
};
</script>

<style scoped lang="less">
.live-card-wrap {
  cursor: pointer;

  .live-card {
    text-align: left;
    border: none;
  }

  .time {
    font-size: 12px;
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 20px;
    &:before,
    &:after {
      content: " ";
      position: absolute;
      width: 10px;
      height: 10px;
      border-radius: 5px;
    }
    &:before {
      background: #299ffc;
      left: 0;
    }
    &:after {
      background: #6abcfe;
      left: 5px;
    }
  }
  .live-info {
    display: flex;
    flex-direction: row;
    margin-top: 8px;

    .logo {
      width: 100px;
      height: 100px;
      border-radius: 10px;
      margin-right: 10px;
      flex-shrink: 0;
    }

    .right-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100px;
      flex: 1;

      .name {
        font-weight: 600;
        font-size: 16px;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
      }

      .price-num {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: baseline;

        .buy-num {
          color: #666;
          font-size: 12px;
        }

        .price {
          font-size: 18px;
          font-weight: 500;
          color: #fb632a;
        }
      }

      .to-watch {
        color: #3399ff;
        font-size: 16px;
        font-weight: 500;
        text-align: right;
      }
    }
  }
}
</style>
