import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./plugins/moment";
import "./plugins/vue-cookies";
import "./plugins/element.js";
import "./plugins/axios";
import Global from "@/global";

if (
  navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  )
) {
  // 移动端逻辑
  console.log("移动端逻辑");
  store.commit("signAdaptive", "model");
} else {
  // PC端逻辑
  console.log("PC端逻辑");
  store.commit("signAdaptive", "Pc");
}

Vue.config.productionTip = false;
Vue.prototype.$global = Global;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
