<template>
  <div class="layout-container">
    <div class="sel-month">
      <span>选择时间：</span>
      <div class="date-picker">
        <div class="btn-sub" @click="subMonth">-</div>
        <el-date-picker
          v-model="month"
          :clearable="false"
          placeholder="选择月份"
          size="small"
          type="month"
          @change="monthChange"
        >
        </el-date-picker>
        <div class="btn-plus" @click="addMonth">+</div>
      </div>
    </div>

    <div class="live-list">
      <LiveCard v-for="(item, index) in data" :data="item" :key="index" />
    </div>
    <fouter></fouter>
  </div>
</template>

<script>
import moment from "moment/moment";
import LiveCard from "@/components/LiveCard.vue";
import Fouter from "@/components/fouter/fouter.vue";
import { mapGetters, mapState } from "vuex";

export default {
  name: "LiveList",
  components: { Fouter, LiveCard },
  data() {
    return {
      month: moment().format("YYYY-MM"),
      data: []
    };
  },
  created() {
    this.getData();
  },
  computed: {
    ...mapState(["currentNavIndex", "navList"]),
    ...mapGetters(["currentNav", "currentBanners"]),
    routePathType() {
      return this.$route.path;
    }
  },
  watch: {
    month: function month() {
      this.getData();
    },
    currentNavIndex: function navChange() {
      this.data = [];
      this.getData();
    }
  },
  methods: {
    getData: async function() {
      const curDateStr = moment(this.month).format("YYYY-MM-01 00:00:00");
      const currentMonth = Number(moment(curDateStr).format("X"));
      const payload = {
        app_professionId: this.currentNav.idStr || 1,
        currentMonth
      };
      const resp = await this.axios.post(
        "/shop1Api/topic/getGoodsSectionByMonth",
        payload
      );
      const { data: { entity } = {} } = resp;
      const fmtData = this.sortAndFlat(entity || {});
      this.data = this.data.concat(fmtData);
    },

    /**
     * 排序 扁平化数组
     * @param val
     * @returns {FlatArray<*[], 1>[]}
     */
    sortAndFlat: function(val) {
      let values = [];
      Object.keys(val)
        .sort()
        .map(key => {
          values.push(val[key]);
        });
      return values.flat();
    },
    monthChange: function(val) {
      this.month = moment(val).format("YYYY-MM");
      this.data = [];
    },
    subMonth: function() {
      this.month = moment(this.month)
        .subtract(1, "month")
        .format("YYYY-MM");
      this.data = [];
    },
    addMonth: function() {
      this.month = moment(this.month)
        .add(1, "month")
        .format("YYYY-MM");
      this.data = [];
    }
  }
};
</script>

<style lang="less" scoped>
.layout-container {
  background: #f5f5f5;
  flex: 1;
  display: flex;
  min-height: 100%;
  flex-direction: column;

  .sel-month {
    padding: 20px 10% 0 10%;
    display: flex;
    align-items: center;

    .date-picker {
      display: flex;
      flex-direction: row;
      align-items: center;
      border-radius: 4px;
      border: 1px solid #dcdfe6;
      overflow: hidden;

      /deep/ .el-input__inner {
        border: none !important;
        border-radius: 0;
      }
    }

    .btn-sub,
    .btn-plus {
      width: 32px;
      background: #f5f7fa;
      height: 32px;
      display: flex;
      font-size: 18px;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
}
.live-list {
  display: grid;
  grid-template-columns: repeat(3, auto);
  padding: 20px 10%;
  gap: 20px;
  flex: 1;
}

@media screen and (max-width: 900px) {
  .sel-month {
    padding: 10px !important;
  }
  .live-list {
    grid-template-columns: repeat(1, auto);
    padding: 10px;
  }
}
</style>
