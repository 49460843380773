<template>
  <div class="layout-page" id="layout-page">
    <!-- <h1>{{ msg }}</h1> -->
    <el-container class="container">
      <el-header
        :style="
          adaptive === 'Pc'
            ? 'height: 60px;line-height: 60px;'
            : 'height: 0.6rem;line-height: 0.6rem;'
        "
      >
        <div
          class="head-box"
          :style="adaptive === 'model' ? 'padding:0 0.2rem' : ''"
        >
          <div :class="adaptive === 'Pc' ? 'logo-box' : 'logo-box-model'">
            <img
              :style="adaptive === 'Pc' ? '' : 'width:1.6rem'"
              class="logo-img"
              @click="toIndex"
              alt="logo"
              src="https://ytk-front.oss-cn-beijing.aliyuncs.com/pc/doctorTopic/header/logo.png"
            />
          </div>
          <div
            :class="adaptive === 'Pc' ? 'postionBox' : 'postionBox-model'"
            :style="adaptive === 'Pc' ? '' : 'display:flex;'"
            v-if="classListTyp && !!navList.length"
          >
            <div
              :class="adaptive === 'model' ? 'postion_con' : ''"
              v-for="(item, index) in arr"
              :key="index"
              @click="pointtabcolor(item, index)"
            ></div>
          </div>
          <div
            class="user-box"
            :style="
              adaptive === 'model' ? 'display : flex;padding-right:0.2rem' : ''
            "
          >
            <a
              href="/"
              :class="
                adaptive === 'Pc'
                  ? 'postion_con aSizeDef'
                  : 'postion_con_model postion_con_model_a aSizeDef'
              "
              >首页
            </a>
            <a
              href="https://shop.med163.net/course/index"
              :class="
                adaptive === 'Pc'
                  ? 'postion_con aSizeDef'
                  : 'postion_con_model postion_con_model_a aSizeDef'
              "
            >
              学员专享
            </a>
            <template v-if="['/index', '/live-list'].includes(routePathType)">
              <div
                :class="[
                  'postion_con',
                  adaptive === 'Pc' ? '' : 'postion_con_model'
                ]"
                v-for="(item, index) in arr"
                :key="index"
                @click="pointtabcolor(item, index)"
              >
                <div
                  :class="
                    index === colin ? 'postion_freen' : 'postion_con-model'
                  "
                >
                  {{ item.name }}
                </div>
                <div class="fouter-color"></div>
              </div>
            </template>

            <template v-if="isLogin">
              <el-button
                class="button"
                v-if="adaptive === 'Pc'"
                style="color: #333333;"
                size="small"
                type="text"
                @click="goUserLive"
                >我的直播
              </el-button>
              <el-button
                class="button"
                v-if="adaptive === 'Pc'"
                style="color: #333333;"
                size="small"
                type="text"
                @click="goUserCourseCenter"
                >我的课程
              </el-button>
              <div
                style="color: #333333;font-size: 0.24rem;margin-right: 0.2rem"
                v-else
                @click="goUserCourseCenter"
              >
                我的课程
              </div>
              <el-button
                v-if="adaptive === 'Pc'"
                style="color: #333333;"
                class="button"
                size="small"
                type="text"
                @click="logOut"
                >退出
              </el-button>
              <div
                style="color: #333333;font-size: 0.24rem;"
                v-else
                @click="logOut"
              >
                退出
              </div>
            </template>
            <template v-else>
              <button
                style="width: 82px;height: 32px;color: #3399FF;outline:none;background:white;border: 1px solid #3399FF;border-radius: 30px;"
                v-if="adaptive === 'Pc'"
                class="button"
                size="small"
                type="primary"
                @click="toSignIn"
              >
                登录
              </button>
              <div v-else class="button-model" @click="toSignIn">登录</div>
              <div v-if="adaptive === 'model'" style="color: black">|</div>
              <button
                style="margin-left:30px;width: 82px;height: 32px;color: white;outline:none;background:dodgerblue;border:0;border-radius: 30px;"
                v-if="adaptive === 'Pc'"
                class="button"
                size="small"
                type="info"
                @click="toSignUp"
              >
                注册
              </button>
              <div
                style="margin: 0"
                v-else
                class="button-model"
                @click="toSignUp"
              >
                注册
              </div>
            </template>
          </div>
        </div>
      </el-header>
      <div
        class="type-nav"
        v-if="['/index', '/live-list'].includes(routePathType)"
      >
        <div
          :class="['type-nav-item', typeNav === 1 ? 'type-nav-active' : '']"
          @click="changeType(1)"
        >
          课程
        </div>
        <div
          :class="['type-nav-item', typeNav === 2 ? 'type-nav-active' : '']"
          @click="changeType(2)"
        >
          直播
        </div>
      </div>
      <el-main style="padding: 0;width: 100%;height: 100%;">
        <router-view v-if="isReady"></router-view>
      </el-main>
    </el-container>
    <el-drawer
      custom-class="nav-drawer"
      title="菜单"
      :visible.sync="drawer"
      direction="rtl"
      :withHeader="false"
    >
      <div class="nav-mini">
        <div
          class="nav-mini-item"
          v-for="(item, index) in navList"
          :key="index"
          @click="miniNavClick(index)"
        >
          {{ item.name }}
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Layout",
  props: {
    msg: String
  },
  data() {
    return {
      type: "signIn", //初始化为登录界面
      activeName: "0",
      drawer: false,
      isReady: false,
      //列表数据
      classListTyp: false,
      colin: 0,
      //适配类型
      adaptive: "",
      arr: [
        {
          id: 73,
          keyword: "recommend_goods",
          layer: 0,
          name: "西医",
          sort: 0,
          status: 0,
          type: -1
        },
        {
          id: 74,
          keyword: "recommend_goods",
          layer: 0,
          name: "中医",
          sort: 0,
          status: 0,
          type: -1
        }
      ],
      typeNav: 1
    };
  },
  computed: {
    ...mapState([
      // 映射 this.count 为 store.state.count
      "isLogin",
      "currentNavIndex",
      "navList"
    ]),
    routePathType() {
      return this.$route.path;
    }
  },
  watch: {
    currentNavIndex() {
      this.activeName = this.currentNavIndex + "";
    },
    routePathType(n) {
      if (n === "/index") {
        this.classListTyp = true;
      } else {
        this.classListTyp = false;
      }
    }
  },
  methods: {
    getNavList() {
      let ref = this;
      // ref.axios.post("/main1Api/profession/list", {}).then(res => {
      ref.axios.post("/shop1Api/get/goodsTypeList", {}).then(res => {
        console.log(res.data.entity, ">>>>>111>>>>");
        // this.arrSon = res.data.entity
        this.arr.forEach(item => {
          item.activityList = res.data.entity;
        });
        ref.$store.commit("setNavList", this.arr);
        console.log(this.$store.state.navList, "------111-----");
        // 设置当前激活状态的菜单(有可能重新打开了一个浏览器tab页需要保持一致性)
        ref.activeName = ref.currentNavIndex + "";
        // 去网index页面
        ref.$store.commit("setCurrentNavIndex", ref.currentNavIndex);
        // 显示主题内容
        ref.isReady = true;
        // return
        // if (res.data.success) {
        //   // 存储当前用户菜单
        //   ref.$store.commit("setNavList", res.data.entity);
        //   // 设置当前激活状态的菜单(有可能重新打开了一个浏览器tab页需要保持一致性)
        //   ref.activeName = ref.currentNavIndex + "";
        //   // 去网index页面
        //   ref.$store.commit("setCurrentNavIndex", ref.currentNavIndex);
        //   // 显示主题内容
        //   ref.isReady = true;
        // }
      });
    },
    logOut() {
      // 退出帐号
      let ref = this;
      ref.axios.post("/user1Api/exit", {}).then(res => {
        if (res.data.success) {
          ref.$message({
            message: "帐号已退出",
            type: "success"
          });
          ref.$store.commit("setLoginState", false);
          localStorage.clear();
          location.reload();
          // ref.$router.push({ path: "/sign-in" });
        }
      });
    },
    goUserCourseCenter() {
      // 去往用户课程中心
      if (this.$route.path !== "/user-course-center") {
        this.$router.push({ path: "/user-course-center" });
      }
    },
    goUserLive() {
      // 去往用户直播中心
      if (this.$route.path !== "/user-live") {
        this.$router.push({ path: "/user-live" });
      }
    },
    toIndex() {
      // 首页
      if (this.$route.path !== "/index") {
        this.$router.push({ path: "/index" });
      }
    },
    toSignIn() {
      // 登录

      this.$store.commit("signinAppType", (this.$store.state.signinType += 1));
      // return
      // if (this.$route.path !== "/sign-in") {
      //   this.$router.push({ path: "/sign-in" });
      // }
    },
    toSignUp() {
      // 注册
      this.$store.commit("signUpAppType", (this.$store.state.signUpType += 1));
      // if (this.$route.path !== "/sign-up") {
      //   this.$router.push({ path: "/sign-up" });
      // }
    },
    pointtabcolor(item, index) {
      // 导航被点击
      this.colin = index;
      this.$store.commit("setCurrentNavIndex", item.id);
      this.$store.commit("setCurrentNavIndexType", 1);
      this.$store.commit("setCurrentNavIndexId", index + 1);
      this.$store.commit("setCurrentNavGoodType", "");
      /*this.$nextTick(() => {
        if (this.$route.path !== "/index") {
          this.$router.push("/index");
        }
      });*/
    },
    miniNavClick(index) {
      // 导航被点击
      let ref = this;
      // 将vuex 当前菜单对象设置为当前点击菜单对象
      ref.$store.commit("setCurrentNavIndex", index);
      ref.$nextTick(() => {
        if (ref.$route.path !== "/index") ref.$router.push({ path: "/index" });
      });
      ref.drawer = false;
    },
    changeType(type) {
      this.typeNav = type;
      if (type === 1) {
        this.$router.push("/index");
      } else {
        this.$router.push("/live-list");
      }
    }
  },
  beforeCreate() {
    // vuex数据持久化在localstoreage 中存储，不能根据 sid设置登录状态
    // 未登录状态重定向
    // let ref = this;
    // if (ref.$cookies.isKey("sid") && ref.$cookies.isKey("stime")) {
    //   // cookie有效登录状态
    //   ref.$store.commit("setLoginState", true);
    // } else {
    //   ref.$store.commit("setLoginState", false);
    //   // 跳转登陆页面
    //   if (ref.$route.path !== "/sign-in") {
    //     ref.$router.push({ path: "/sign-in" });
    //   }
    // }
  },
  created() {
    this.adaptive = this.$store.state.adaptive;
    this.classListTyp = this.$route.path === "/index";
  },
  mounted() {
    this.getNavList();

    if (this.$route.path === "/live-list") {
      this.typeNav = 2;
    } else {
      this.typeNav = 1;
    }
  }
  // comments: {
  // }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped lang="less">
@deepBlueForLayout: #2d313f;
.layout-page {
  & > .container {
    height: 100vh;
    text-align: center;

    .el-header,
    .el-footer {
      background-color: #ffffff;
      color: #fff;
    }

    // reset element-ui style
    .el-header {
      .el-col {
        min-height: 1px;
      }
    }

    .el-main {
      // background-color: #e9eef3;
      color: #333;

      .sign-in-box {
        margin: 50px auto;
      }
    }

    .head-box {
      display: flex;
      width: 100%;
      padding: 0 10%;
      box-sizing: border-box;

      .logo-box {
        flex-basis: 130px;
        height: 60px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .logo-img {
          display: block;
          cursor: pointer;
        }
      }

      .logo-box-model {
        //width: calc(100vw/130);
        width: 1.3rem;
        height: 0.6rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .logo-img {
          display: block;
          cursor: pointer;
        }
      }

      .nav-box {
        flex: auto;
        position: absolute;
        top: 60px;
        left: 360px;
        z-index: 111;
      }

      .nav-box-model {
        flex: auto;
        position: absolute;
        top: 0.6rem;
        left: 3.6rem;
        z-index: 111;
      }

      .facebook {
        color: #333333;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        text-decoration: none;
        margin-right: 20px;
        display: flex;
        align-items: center;
      }

      .facebook-model {
        color: white;
        text-decoration: none;
        margin-right: 0.4rem;
        font-size: 0.12rem;
      }

      .user-box {
        display: flex;
        flex-direction: row;
        text-align: end;
        width: 90%;
        align-items: center;
        justify-content: flex-end;

        .postion_con {
          color: #333333;
          //width: 70px;
          margin-right: 50px;
          font-size: 16px;
          font-family: Adobe Heiti Std;
          font-weight: bold;
          cursor: pointer;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          transition: all 0.3s;

          .fouter-color {
            width: 34px;
            height: 2px;
            background: #3399ff;
          }
        }

        .postion_con_model {
          color: black;
          width: 0.7rem;
          margin-right: 0.2rem;
          cursor: pointer;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          transition: all 0.3s;

          .fouter-color {
            width: 0.34rem;
            height: 0.02rem;
            background: #3399ff;
          }

          .postion_freen {
            font-size: 0.3rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #3399ff;
            line-height: 17px;
            margin: 0.1rem 0 0.1rem;
            transition: all 0.3s;
          }

          .postion_con-model {
            font-size: 0.3rem;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: black;
            line-height: 17px;
            margin: 0.1rem 0 0.1rem;
          }
        }

        .postion_con_model_a {
          width: 1.7rem;
        }

        .aSizeDef {
          text-decoration: none;
          outline: none;
          color: #000;
        }

        .user-box-qrCode {
          position: absolute;
          top: 54px;
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 20;
        }

        .button {
          width: 56px;
          height: 32px;
        }

        .button-model {
          width: 0.8rem;
          color: #333333;
          height: 0.4rem;
          font-size: 0.2rem;
          border-radius: 0.1rem;
          outline: none;
          padding: 0;
          border: 0;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .myClass {
        width: 101px;
        height: 100%;
        color: #a7a7a7;
        background: #373b48;
        margin-left: 94px;
        cursor: pointer;
        font-weight: normal;
        font-size: 14px;
        font-family: Hiragino Sans GB;
      }

      .postion_freen {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #3399ff;
        line-height: 17px;
        margin: 10px 0 10px;
      }

      .postionBox {
        width: 20%;
        box-sizing: border-box;
        display: flex;
        justify-content: center;

        .postion_con {
          color: #333333;
          margin-right: 20px;
          font-size: 16px;
          font-family: Adobe Heiti Std;
          font-weight: bold;
          cursor: pointer;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .fouter-color {
            width: 34px;
            height: 2px;
            background: #3399ff;
          }
        }
      }

      //隐藏滚动条
      .postionBox-model::-webkit-scrollbar {
        display: none;
      }

      .postionBox-model {
        width: 100%;
        padding-left: 0.2rem;
        position: absolute;
        background: white;
        box-sizing: border-box;
        overflow: scroll;
        top: 0.6rem;
        left: 0;
        z-index: 90;
        display: flex;
        //flex-wrap: wrap;
        .postion_con {
          //width: 1rem;
          //margin-right: 0.3rem;
          padding-right: 0.3rem;
          font-size: 0.16rem;
          font-family: Adobe Heiti Std;
          font-weight: bold;
          color: #000000;
          cursor: pointer;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .postion_con-model {
            font-size: 0.18rem;
            margin: 0.1rem 0 0.1rem;
            line-height: 17px;
          }

          .fouter-color {
            width: 1rem;
            height: 0.02rem;
            background: #2065ff;
          }
        }
      }
    }
  }

  .type-nav {
    background: #f5f5f5;
    display: flex;
    align-items: center;
    padding: 10px 10% 0 10%;
    font-size: 18px;
    font-weight: bold;
    .type-nav-item {
      margin-right: 20px;
      cursor: pointer;
    }

    .type-nav-active {
      color: rgb(51, 153, 255);
    }
  }
}

@media screen and (max-width: 550px) {
  .layout-page {
    & > .container {
      height: 100vh;
      text-align: center;

      .el-header,
      .el-footer {
        //background-color: @deepBlueForLayout;
        color: #fff;
      }

      // reset element-ui style
      .el-header {
        //height: 60px;
        //line-height: 60px;
        .el-col {
          min-height: 1px;
        }
      }

      .el-main {
        // background-color: #e9eef3;
        color: #333;

        .sign-in-box {
          margin: 50px auto;
        }
      }

      .head-box {
        display: flex;
        width: 100%;
        padding: 0 15%;
        box-sizing: border-box;

        .logo-box {
          flex-basis: 130px;
          height: 60px;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .logo-img {
            display: block;
            cursor: pointer;
          }
        }

        .logo-box-model {
          //width: calc(100vw/130);
          width: 1.3rem;
          height: 0.6rem;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .logo-img {
            display: block;
            cursor: pointer;
          }
        }

        .nav-box {
          flex: auto;
          position: absolute;
          top: 60px;
          left: 360px;
          z-index: 111;
        }

        .nav-box-model {
          flex: auto;
          position: absolute;
          top: 0.6rem;
          left: 3.6rem;
          z-index: 111;
        }

        .user-box {
          display: none;
        }

        .myClass {
          width: 101px;
          height: 100%;
          color: #a7a7a7;
          background: #373b48;
          margin-left: 94px;
          cursor: pointer;
          font-weight: normal;
          font-size: 14px;
          font-family: Hiragino Sans GB;
        }

        .postion_freen {
          color: #2065ff;
          line-height: 55px;
          margin: 10px 0 10px;
        }

        .postionBox {
          display: none;
        }

        //隐藏滚动条
        .postionBox-model::-webkit-scrollbar {
          display: none;
        }

        .postionBox-model {
          display: none;
        }
      }

      .boxpostionBox {
        display: none;
      }
    }
  }
}
</style>
<style lang="less">
@deepBlueForLayout: #2d313f;
#layout-page {
  .nav-box {
    .layout-tabs {
      .el-tabs__item {
        color: white;

        &.is-active {
          color: white;
        }
      }

      .el-tabs__nav-wrap::after {
        background-color: @deepBlueForLayout;
      }
    }
  }

  .nav-drawer {
    .nav-mini {
      padding: 20px 5px;

      .nav-mini-item {
        padding: 10px;
        border-bottom: 1px solid #eee;
      }
    }
  }
}

.el-header {
  padding: 0 !important;
}
</style>
