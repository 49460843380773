<template>
  <div class="main">
    <div id="player-container" class="bjc-player-wrapper">
      <!--      <video class="video video-js vjs-default-skin"></video>-->
    </div>
  </div>
</template>

<script>
export default {
  name: "onDemandVideo",
  data() {
    return {};
  },
  mounted() {
    const userInfo = this.$store.state.userInfo;
    console.log("=====>>>", userInfo);
    // let instance = new BjcPlayer(domSelector, param);  // 初始化实例
    // let player = new BjcPlayer($('#main').find('.video')[0], {
    /*new window.BjcPlayer(document.getElementsByClassName("video"), {
      // token: "cJ_IfPQ4OIRWRpkCf_sKaJQjHJd60qmfJq78w9MAbUR0U-mYN3AvOWOq-y7-VeBX",
      token: this.$route.query.token,
      // vid: "72483994",
      vid: this.$route.query.vid,
      playbackRates: [0.7, 1, 1.2, 1.5, 2], // 倍速 传入 [] 隐藏倍速列表
      privateDomainPrefix: "", // 专属域名前缀 0.0.2版本开始支持
      user_name: "用户名", // 用户名， 主要用于数据统计
      user_number: "12345", // 用户id， 主要用于数据统计
      onended: function(data) {
        console.log("onplayend event", data);
      },
      onplay: function() {
        console.log("onplaybegin event");
      },
      onpause: function() {
        console.log("onplaypause event");
      },
      ontimeupdate: function(data) {
        console.log("ontimeupdate event");
        console.log("currentTime:" + data.currentTime);
      },
      onseeked: function(data) {
        console.log("onseek event");
        console.log("seekTime:" + data.currentTime);
      },
      onerror: function(data) {
        console.log("onerror event");
        console.log(data);
      }
    });*/

    new window.BPlayer({
      container: document.getElementById("player-container"), // 设置播放器初始化的父容器
      memory: true, // 是否开启记忆播放
      enableDrag: true, // 是否允许拖拽进度条
      theme: "", // 主题色，默认值 #1795ff
      rateList: [0.5, 0.75, 1, 1.25, 1.5, 2], // 倍速列表
      logo: {
        url: "", // logo 图片地址
        link: "" // logo 点击跳转地址
      },
      preload: "meta", // 预加载，推荐设置 meta
      volume: 0.75, // 默认音量设置 0 - 1
      quality: "superHD", // 默认清晰度
      qualityList: [], // 清晰度列表，将后台转码的清晰度再做一次过滤，可选值 low（标清），high（高清），superHD（超清），720p，1080p，默认为 [] 读取后台转出的所有清晰度
      autoplay: true, // 自动播放
      disableEncrypt: false, // 是否禁止播放加密
      supportCdnSwitch: true, // 是否支持CDN线路切换
      token: this.$route.query.token,
      vid: this.$route.query.vid,
      plugins: {
        marquee: {
          displayMode: "roll", // 展示形式滚动roll 或者闪烁 blink
          rollDuration: 10, // 滚动间隔时长 单位秒
          blinkDuration: 10, // 闪烁间隔时长 单位秒
          type: 1, // 1 读取value设置的固定值 2 读取 user.name 昵称
          count: 1, // 跑马灯单双数 1 单数 2 双数
          color: "#ffffff", // 跑马灯颜色
          fontOpacity: 0.7, // 跑马灯透明度 0.01 - 1
          fontSize: 20, // 跑马灯字体大小 单位px
          fontBold: 0, //跑马灯文字加粗 0 不加粗 1 加粗
          backgroundColor: "#000000", // 跑马灯背景色 默认#1795ff
          backgroundOpacity: 0.4, // 跑马灯背景透明度 0.01 - 1
          position: "random", // 跑马灯位置 random 随机 top 顶部 bottom 底部
          value: `研大医学-${userInfo?.mobile}` // 跑马灯固定值,
        }
      },
      user: {
        name: userInfo.nickname, // 观看的学员用户名
        number: userInfo.userId // 观看的学员number
      }
    });
  }
};
</script>

<style scoped lang="less">
.main {
  width: 100%;
  height: 100%;
  background: #2c3e50;
}
.bjc-player-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bjc-player-wrapper .video-js {
  width: 80%;
  height: 80%;
}
</style>
