<template>
  <div class="index-page" id="index-page">
    <div
      :class="adaptive === 'Pc' ? 'boxpostionBox' : 'boxPostionBox-model'"
      v-if="classListTyp && !!navList.length"
    >
      <div
        class="postion_con"
        :style="clickNative === index ? 'color:#3399FF;' : 'color: #333333;'"
        v-for="(item, index) in arrSon"
        :key="index"
        @click="pointtabcolor(item, index)"
      >
        <div :class="index === colin ? 'postion_freen' : 'postion_con-model'">
          {{ item.name }}
        </div>
      </div>
    </div>
    <div
      v-if="adaptive === 'Pc' && classListTyp && !!navList.length"
      class="user-box-qrCode"
    >
      <img
        style="width: 15px;height: 22px;margin:6px 10px 0 0;"
        src="https://ytk-front.oss-cn-beijing.aliyuncs.com/pc/doctorTopic/header/phoneIcon.png"
        alt="手机"
      />
      <a class="facebook weixin" href="javascript:">
        下载研大APP
        <img
          class="qrcode"
          src="https://ytk-front.oss-cn-beijing.aliyuncs.com/pc/doctorTopic/header/erweima.png"
          alt="微信二维码"
        />
      </a>
    </div>
    <div
      v-if="adaptive === 'model' && classListTyp && !!navList.length"
      style="width:100%;margin-top:-0.5rem;background: #f5f5f5;color:black;display: flex;align-items: center;justify-content: flex-end"
    >
      <img
        style="width: 0.2rem;height: 0.3rem;margin-right: 0.1rem;"
        src="https://ytk-front.oss-cn-beijing.aliyuncs.com/pc/doctorTopic/header/phoneIcon.png"
        alt="手机"
      />
      <a
        class="facebook-model weixin"
        style="color: black"
        href="http://main.med163.net/share.jsp?android=http://android.myapp.com/myapp/detail.htm?apkName=com.yanda.ydapp&ADTAG=mobile&ios=https://apps.apple.com/cn/app/id1537346151"
      >
        App下载
      </a>
    </div>
    <div
      class="index-page-box"
      :style="
        adaptive === 'Pc'
          ? 'flex: 1;width: 80%;padding:0 10%;'
          : 'flex: 1;width: 100%;margin: 0.4rem auto 0;'
      "
    >
      <div
        class="goods-box"
        :style="adaptive === 'model' ? 'margin-top:0;' : ''"
      >
        <div class="goods-list">
          <el-card
            :class="
              adaptive === 'Pc' ? 'box-card-course' : 'box-card-course-model'
            "
            shadow="hover"
            v-for="(item, index) in goodsList"
            :key="index"
            @click.native="goCourseDetail(item)"
          >
            <div class="clearfix">
              <div class="clearfix_explain" :title="item.name">
                {{ item.name }}
              </div>
              <el-tag
                size="small"
                effect="dark"
                style="float: right;border:0;display: flex;align-items: center;justify-content: center;background: #3399FF;"
                type="text"
                >{{ item.professionName }}
              </el-tag>
            </div>
            <div class="goods-content">
              <p class="course-times">{{ item.labels }}</p>
              <div class="course-teachers">
                <div
                  class="box-card-teacher"
                  shadow="never"
                  v-for="(itemTeacher, indexTeacher) in item.teacherList"
                  :key="indexTeacher"
                >
                  <img
                    :src="
                      'https://ytk-img.oss-cn-beijing.aliyuncs.com' +
                        itemTeacher.photo
                    "
                    class="teacher-img"
                  />
                  <div class="teacher-name">
                    <span>{{ itemTeacher.name }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="course-fouters">
              <div class="number-price">
                <div class="course-number">
                  <div>已售 {{ item.studyNum }}</div>
                  <div>限售 {{ item.limitSellNum }}</div>
                </div>
                <div
                  class="course-price"
                  :class="{ 'is-free': !item.lablePrice }"
                >
                  <span v-if="!!item.limitTime" class="price-original">{{
                    `￥${item.price}`
                  }}</span>
                  <span class="price-discount">{{ getPrice(item) }}</span>
                </div>
              </div>
              <div class="discunt-box" v-if="!!item.limitTime">
                <span>距优惠结束还有</span>
                <span>{{ durationTime(item.limitTime) }}</span>
              </div>
            </div>
          </el-card>
          <!-- 追加元素，用于对齐 -->
          <div
            class="box-card-course-fake"
            v-for="indexFake in 10"
            :key="indexFake + 'fake'"
          ></div>
        </div>
        <div class="goods-pagination">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="page.total"
            @current-change="handleCurrentChange"
          ></el-pagination>
        </div>
      </div>
    </div>
    <fouter class="fouter_bot"></fouter>
  </div>
</template>

<script>
// @ is an alias to /src
var moment = require("moment");
import { mapState, mapGetters } from "vuex";
import fouter from "../../src/components/fouter/fouter";

export default {
  name: "Index",
  data() {
    return {
      msg: "index page 登陆后首页",
      bannerList: [
        {
          imgUrl: ""
        }
      ],
      carouselIndex: 0,
      goodsList: [],
      page: {
        currentPage: 1,
        pageSize: 10,
        total: 0
      },
      qrList: [
        { label: "研大医学公众号", imgName: "gzh.png" },
        { label: "西医考研医题库", imgName: "ytk.png" }
      ],
      discoountTimer: null, //特惠定时器
      //适配类型
      adaptive: "",
      //列表数据
      classListTyp: false,
      //导航被点击的状态
      clickNative: 0,
      colin: 0,
      arrSon: [],
      arr: [
        {
          id: 73,
          keyword: "recommend_goods",
          layer: 0,
          name: "西医",
          sort: 0,
          status: 0,
          type: -1
        },
        {
          id: 74,
          keyword: "recommend_goods",
          layer: 0,
          name: "中医",
          sort: 0,
          status: 0,
          type: -1
        }
      ]
    };
  },
  computed: {
    ...mapState([
      // 映射 this.count 为 store.state.count
      "currentNavIndex",
      "navList"
    ]),
    ...mapGetters([
      // 映射 this.count 为 store.state.count
      "currentNav",
      "currentBanners"
    ]),
    routePathType() {
      return this.$route.path;
    }
  },
  watch: {
    currentNavIndex: {
      handler: function(val) {
        let ref = this;
        console.log("current nav data", val);
        ref.page.currentPage = 1;
        ref.getBannerList();
        ref.getGoodsList();
      },
      immediate: false
    },
    routePathType(n) {
      if (n === "/index") {
        this.classListTyp = true;
      } else {
        this.classListTyp = false;
      }
    }
  },
  methods: {
    pointtabcolor(item, index) {
      // 导航被点击
      this.clickNative = index;
      console.log(item.key, "...222");
      this.colin = index;
      this.$store.commit("setCurrentNavIndex", index + 1);
      this.$store.commit("setCurrentNavIndexType", item.type);
      this.$store.commit("setCurrentNavGoodType", item.key);
      this.$nextTick(() => {
        if (this.$route.path !== "/index") {
          this.$router.push("/index");
        }
      });
    },
    getNavList() {
      let ref = this;
      // ref.axios.post("/main1Api/profession/list", {}).then(res => {
      ref.axios.post("/shop1Api/get/goodsTypeList", {}).then(res => {
        console.log(res.data.entity, ">>>>>111>>>>");
        this.arrSon = res.data.entity;
        this.arr.forEach(item => {
          item.activityList = res.data.entity;
        });
        ref.$store.commit("setNavList", this.arr);
        console.log(this.$store.state.navList, "------111-----");
        // 设置当前激活状态的菜单(有可能重新打开了一个浏览器tab页需要保持一致性)
        ref.activeName = ref.currentNavIndex + "";
        // 去网index页面
        ref.$store.commit("setCurrentNavIndex", ref.currentNavIndex);
        // 显示主题内容
        ref.isReady = true;
        // return
        // if (res.data.success) {
        //   // 存储当前用户菜单
        //   ref.$store.commit("setNavList", res.data.entity);
        //   // 设置当前激活状态的菜单(有可能重新打开了一个浏览器tab页需要保持一致性)
        //   ref.activeName = ref.currentNavIndex + "";
        //   // 去网index页面
        //   ref.$store.commit("setCurrentNavIndex", ref.currentNavIndex);
        //   // 显示主题内容
        //   ref.isReady = true;
        // }
      });
    },
    carouselChange(index) {
      // console.log("carousel-change", index);
      this.carouselIndex = index;
    },
    getPrice(item) {
      let priceText = "";
      if (item.labelPrice) {
        priceText = `￥${item.labelPrice}`;
      } else {
        priceText = "免费";
      }
      return priceText;
    },
    handleCurrentChange(currentPage) {
      this.page.currentPage = currentPage;
      this.getGoodsList();
    },
    bannerClick(item) {
      let ref = this;
      if (item.jumpType === 1) {
        window.open(item.moreUrl, "_blank");
      } else {
        // jumpType :1 外部网页 2 商品详情
        ref.goCourseDetail(item, item.otherId);
      }
    },
    goCourseDetail(item, otherId) {
      let ref = this;
      // 跳转至课程详情
      let id = otherId ? otherId : item.id;
      // console.log("go page item.id:" + item.id);
      if (item.sellType === 1) {
        // 联报
        ref.$router.push({
          path: "/course-detail-boundle",
          query: { id }
        });
      } else {
        // item.sellType===2 单网课
        ref.$router.push({
          path: "/course-detail-single",
          query: { id }
        });
      }
    },
    getGoodsList() {
      // 获取当前分类的课程列表
      let ref = this;
      if (!ref.currentNav.goodsType) {
        this.clickNative = 0;
      }
      let paramData = {
        professionId: ref.currentNav.idStr,
        // professionId: ref.currentNavIndex,
        // 西医1 中医2
        type: ref.currentNav.type,
        // type: 0,
        currentPage: ref.page.currentPage,
        pageSize: ref.page.pageSize,
        goodsType: ref.currentNav.goodsType || ""
      };
      ref.axios.post("/shop1Api/get/goods/list", paramData).then(res => {
        let data = res.data;
        if (res.data.success) {
          // data
          ref.goodsList = data.entity.goodsList;
          // page
          ref.page.total = data.entity.page.totalResultSize;
          ref.initTimer();
        }
      });
      ref.initTimer();
    },
    getBannerList() {
      let ref = this;
      ref.bannerList = JSON.parse(JSON.stringify(ref.currentBanners));
    },
    durationTime(timestamp) {
      let result = "";
      if (timestamp) {
        result =
          moment.duration(timestamp).months() +
          "月" +
          moment.duration(timestamp).days() +
          "天" +
          moment.duration(timestamp).hours() +
          "小时" +
          moment.duration(timestamp).minutes() +
          "分钟" +
          moment.duration(timestamp).seconds() +
          "秒";
      }
      return result;
    },
    initTimer() {
      let ref = this;
      ref.discoountTimer = setInterval(function() {
        console.log("loop");
        if (
          ref.goodsList.every(ele => {
            return !ele.limitTime;
          })
        ) {
          clearInterval(ref.discoountTimer);
          return false;
        } else {
          ref.goodsList.forEach(ele => {
            if (ele.limitTime > 0) {
              ele.limitTime -= 1000;
            }
          });
        }
      }, 1000);
    }
  },
  mounted() {
    this.adaptive = this.$store.state.adaptive;
    if (this.$route.path === "/index") {
      this.classListTyp = true;
    } else {
      this.classListTyp = false;
    }
    // console.log(this.$store.state.adaptive,'>>>>>>>>>>!!!')
    this.adaptive = this.$store.state.adaptive;
    this.getBannerList();
    this.getGoodsList();
    this.getNavList();
  },
  components: {
    fouter
  }
};
</script>
<style lang="less" scoped>
html {
  height: 100%;
}

.index-page {
  background: #f5f5f5;
  text-align: left;
  padding-top: 10px;
  min-height: calc(100vh - 160px);
  //display: flex;
  //flex-direction: column;

  .boxpostionBox {
    width: 100%;
    background: #f5f5f5;
    box-sizing: border-box;
    display: flex;
    height: 50px;
    padding: 0 10%;
    text-align: center;

    .postion_con {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      height: 50px;
      margin-right: 40px;
      cursor: pointer;
      align-items: center;
      justify-content: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .fouter-color {
        width: 34px;
        height: 2px;
        background: #3399ff;
      }
    }

    .postion_con:hover {
      color: rebeccapurple;
    }
  }

  .boxPostionBox-model {
    width: 100%;
    //padding-left: 0.5rem;
    background: #f5f5f5;
    color: brown;
    box-sizing: border-box;
    overflow: scroll;
    padding-left: 0.5rem;
    //left: 0.5rem;
    z-index: 90;
    display: flex;
    .postion_con {
      margin-right: 0.5rem;
      font-size: 0.16rem;
      font-family: Adobe Heiti Std;
      font-weight: bold;
      color: #000000;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .postion_freen {
        font-size: 0.3rem;
        margin: 0.1rem 0 0.1rem;
        line-height: 17px;
      }
      .postion_con-model {
        font-size: 0.2rem;
        margin: 0.1rem 0 0.1rem;
        line-height: 17px;
      }

      .fouter-color {
        //width: 1rem;
        //height: 0.02rem;
        //background: #2065ff;
      }
    }
  }

  .user-box-qrCode {
    //position: absolute;
    //top: 54px;
    background: #f5f5f5;
    width: 100%;
    box-sizing: border-box;
    padding: 0 10%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: -40px;
  }
  .facebook {
    color: #333333;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    text-decoration: none;
    //margin-right: 20px;
  }
  .facebook-model {
    color: white;
    text-decoration: none;
    margin-right: 0.4rem;
    font-size: 0.12rem;
  }

  .weixin {
    position: relative;
  }

  .weixin img.qrcode {
    position: absolute;
    z-index: 99;
    top: 60px;
    left: -40px;
    width: 158px;
    max-width: none;
    height: 198px;
    transform: scale(0);
    transform-origin: top;
    opacity: 0;
    -webkit-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }

  .weixin:hover img.qrcode {
    transform: scale(1);
    opacity: 1;
  }

  .banner-list-box {
    width: 100%;
    height: 400px;
    margin-top: 70px;
    overflow: hidden;
    position: relative;

    .blur-background {
      position: absolute;
      left: 0;
      top: 0;
      display: block;
      width: 100%;
      height: 100%;
      background-position: center;
      background-size: cover;
      filter: blur(10px);
    }

    .carousel-self {
      max-width: 1200px;
      height: 100%;
      margin: 0 auto;
    }

    .banner-item {
      height: 100%;

      .banner-img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }

  .banner-list-box-model {
    width: 100%;
    height: 4rem;
    margin-top: 1rem;
    overflow: hidden;
    position: relative;

    .blur-background {
      position: absolute;
      left: 0;
      top: 0;
      display: block;
      width: 100%;
      height: 100%;
      background-position: center;
      background-size: cover;
      filter: blur(10px);
    }

    .carousel-self {
      max-width: 12rem;
      height: 100%;
      margin: 0 auto;
    }

    .banner-item {
      height: 100%;

      .banner-img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }

  .goods-box {
    margin-top: 31px;

    .goods-list {
      display: flex;
      //justify-content: space-between;
      flex-wrap: wrap;

      .box-card-course-fake {
        width: 450px;
      }

      .box-card-course {
        width: 32%;
        margin: 0 1.5% 35px 0;
        cursor: pointer;
        padding: 0;

        .clearfix {
          display: flex;
          flex-direction: row;
          padding: 31px 20px 10px 20px;
          box-sizing: border-box;

          .clearfix_explain {
            width: 220px;
            height: 55px;
            font-size: 20px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #333333;
            margin-right: 24px;

            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box; //作为弹性伸缩盒子模型显示。
            -webkit-box-orient: vertical; //设置伸缩盒子的子元素排列方式--从上到下垂直排列
            -webkit-line-clamp: 2; //显示的行
          }
        }

        .goods-content {
          padding: 0 20px;
          height: 126px;
          .course-times {
            margin: 0 0 8px 0;
            font-size: 15px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
          }

          .course-teachers {
            display: flex;
            justify-content: flex-start;

            .box-card-teacher {
              //width: 60px;
              max-width: 100px;
              border: none;
              padding: 0 20px 16px 0;
              display: flex;
              flex-direction: column;
              align-items: center;

              .teacher-img {
                width: 50px;
                height: 50px;
                border-radius: 50%;
              }

              .teacher-name {
                text-align: center;
                margin-top: 13px;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #333333;
              }
            }
          }

          .discunt-box {
            line-height: 30px;
            color: red;
            text-align: right;
          }
        }

        .course-fouters {
          padding: 18px 21px 20px 20px;
          background: #fafafa;
          border-radius: 5px;
          .number-price {
            display: flex;
            justify-content: space-between;

            .course-number {
              display: flex;
              flex-direction: column;
              font-size: 12px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #666666;
              line-height: 26px;
            }

            .course-price {
              display: flex;
              flex-direction: row;
              .price-original {
                font-size: 16px;
                color: grey;
                text-decoration: line-through;
              }

              .price-discount {
                font-size: 20px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #ff3333;
                line-height: 20px;

                &.is-free {
                  font-size: 16px;
                }
              }
            }
          }
        }
      }

      .box-card-course:nth-child(3n + 3) {
        margin: 0 0 35px 0;
      }

      .box-card-course-model {
        //flex: 1;
        width: 100%;
        margin: 0 1.5% 0.35rem 1.5%;
        cursor: pointer;
        padding: 0;

        .clearfix {
          display: flex;
          align-items: center;
          flex-direction: row;
          padding: 0.21rem 0.22rem 0.14rem 0;
          height: 0.73rem;
          box-sizing: border-box;

          .clearfix_explain {
            width: 5rem;
            font-size: 0.35rem;
            font-family: Adobe Heiti Std;
            font-weight: bold;
            color: #151515;
            margin-right: 0.24rem;
            overflow: hidden; /*超出部分隐藏*/
            text-overflow: ellipsis; /* 超出部分显示省略号 */
            white-space: nowrap; /*规定段落中的文本不进行换行 */
          }
        }

        .goods-content {
          padding: 0 0.2rem 0.15rem 0;
          .course-times {
            color: grey;
            font-size: 0.3rem;
            margin: 0 0 0.14rem 0;
          }

          .course-teachers {
            display: flex;

            .box-card-teacher {
              border: none;
              padding: 0.2rem 0.2rem 0.2rem 0;
              display: flex;
              flex-direction: column;
              align-items: center;

              .teacher-img {
                width: 0.6rem;
                height: 0.6rem;
                border-radius: 50%;
              }

              .teacher-name {
                text-align: center;
                margin-top: 8px;
              }
            }
          }

          .discunt-box {
            line-height: 0.3rem;
            color: red;
            text-align: right;
          }
        }

        .course-fouters {
          .number-price {
            display: flex;
            justify-content: space-between;
            margin-top: 0.23rem;
            .course-number {
              color: grey;
              font-size: 0.2rem;
            }

            .course-price {
              .price-original {
                font-size: 0.16rem;
                color: grey;
                text-decoration: line-through;
              }

              .price-discount {
                font-size: 0.3rem;
                font-family: Adobe Heiti Std;
                font-weight: normal;
                color: #fb2626;
                line-height: 0.2rem;

                &.is-free {
                  font-size: 0.16rem;
                }
              }
            }
          }
        }
      }
    }

    .goods-pagination {
      padding-top: 100px;
      text-align: center;
    }
  }

  .qr-box {
    display: flex;
    justify-content: center;

    .qr-item {
      margin: 0 50px;
      padding: 0;
      text-align: center;
    }
  }
}

.fouter_bot {
  width: 100%;
  height: 160px;
}
</style>

<style lang="less">
.el-card__body {
  padding: 0;
}
.active {
  background-color: #3399ff !important;
}
</style>
